import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileAlt, FaExpand, FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Card, CardBody } from "../../components/card/card";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";

let zIndexCounter = 1000; // Global z-index counter

function EmbeddedDoc({ selectedDoc, handleBack }) {
  const [iframeWidth, setIframeWidth] = useState(800); // State for iframe width
  const [iframeHeight, setIframeHeight] = useState(600); // State for iframe height
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: 100 }); // State for position
  const [showModal, setShowModal] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(selectedDoc.saved || false); // State for bookmark
  const [modalIframeSrc, setModalIframeSrc] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpenTime, setModalOpenTime] = useState(null);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [cardSizes, setCardSizes] = useState({}); // Store width and height per card
  const [cardZIndices, setCardZIndices] = useState("");
  const iframeRef = useRef(null);
  const dragRef = useRef(null);



  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleResize = (e, direction) => {
    e.preventDefault();

    const cardElement = e.currentTarget.parentElement;
    const initialWidth = cardElement.offsetWidth;
    const initialHeight = cardElement.offsetHeight;
    const initialLeft = cardElement.offsetLeft; // Capture initial left position
    const initialTop = cardElement.offsetTop; // Capture initial top position
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      // Dynamically adjust width and height
      let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
      let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);

      let newLeft = initialLeft + (direction.includes("left") ? deltaX : 0);
      let newTop = initialTop + (direction.includes("top") ? deltaY : 0);

      // Minimum width and height constraints
      newWidth = Math.max(300, newWidth);
      newHeight = Math.max(300, newHeight);

      // Dynamic maximum width and height constraints
      const containerBounds = containerRef.current.getBoundingClientRect();
      const maxWidth = Math.max(containerBounds.width, newWidth); // Allow it to go beyond the container width if needed
      const maxHeight = Math.max(containerBounds.height, newHeight);

      // Constrain the new width and height to the maximum allowed
      newWidth = Math.min(newWidth, maxWidth);
      newHeight = Math.min(newHeight, maxHeight);

      setCardSizes((prev) => ({
       width: newWidth, height: newHeight ,
       left: direction.includes("left") ? newLeft :  initialLeft,
       top: direction.includes("top") ? newTop : initialTop,
      }));
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };


  const increaseIframeWidth = () => {
    setIframeWidth((prevWidth) => {
      const maxWidth = window.innerWidth;
      return Math.min(maxWidth, prevWidth + 50);
    });
  };
  const decreaseIframeWidth = () => {
    setIframeWidth((prevWidth) => Math.max(820, prevWidth - 50));
  };


 

  // Handlers for dragging
  const handleMouseDown = (e) => {
    setIsDragging(true);
    dragRef.current = { offsetX: e.clientX - position.x, offsetY: e.clientY - position.y };
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragRef.current.offsetX;
      const newY = e.clientY - dragRef.current.offsetY;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Handlers for full-screen
  const toggleFullscreen = () => {
    setShowModal(!showModal);
  };


  const token = sessionStorage.getItem("token");


  const handleFullscreenClick = (doc) => {
    setModalIframeSrc(doc?.docs[0]);
    setModalTitle(doc.title);
    setShowModal(true);

    setModalOpenTime(Date.now());
    setButtonsVisible(false);
    setTimeout(() => {
      setButtonsVisible(true);
    }, 1000);
  };

  const handleDragStart = (e) => {
    if (e.target.className.includes("resize-handle")) return;

    e.preventDefault();
    e.stopPropagation();

    e.preventDefault();
   
   
    const cardElement = e.currentTarget;
    const iframe = cardElement.querySelector("iframe");


    iframe.style.pointerEvents = "none";


    const currentLeft = parseFloat(cardElement.style.left) || 0;
    const currentTop = parseFloat(cardElement.style.top) || 0;

    const offsetX = e.clientX - currentLeft;
    const offsetY = e.clientY - currentTop;

    cardElement.style.cursor = "grabbing";

    setCardZIndices(++zIndexCounter)

    const handleMouseMove = (moveEvent) => {
      const newLeft = moveEvent.clientX - offsetX;
      const newTop = moveEvent.clientY - offsetY;
     

      cardElement.style.position = "relative";
      cardElement.style.left = `${newLeft}px`;
      cardElement.style.top = `${newTop}px`;
    };

    const handleMouseUp = () => {
      iframe.style.pointerEvents = "auto";
    
   
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const containerRef = useRef(null);
  const observerRef = useRef();

  // Handlers for bookmark
  const toggleBookmark = async (selectedDoc) => {

    console.log("selectedDoc=>",selectedDoc)
    // const document = documents[index]; // Get the selected document
    // const { id: documentId } = document; // Assuming document has an 'id' field

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmarkDocument`,
        { documentId:selectedDoc?.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setIsBookmarked((prev) => !prev);
      
     
    } catch (error) {
      console.error("Error bookmarking document:", error);
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  // Function to extract data after the first underscore after the last slash
  const extractDocName = (docUrl) => {
    const lastSlashIndex = docUrl.lastIndexOf("/"); // Find the last slash
    const fileNameWithUnderscore = docUrl.substring(lastSlashIndex + 1); // Get the part after the last slash
    const underscoreIndex = fileNameWithUnderscore.indexOf("_"); // Find the first underscore

    if (underscoreIndex !== -1) {
      return fileNameWithUnderscore.substring(underscoreIndex + 1); // Get the part after the underscore
    }

    return fileNameWithUnderscore; // Return the whole string if no underscore found
  };

  return (
    <div
      className="d-flex"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      ref={containerRef}
    >
      <div className="content p-4" style={{ flex: 1 }}>
        {/* Back Button */}
        <button className="btn btn-secondary mb-3" onClick={handleBack}>
          Back to Table
        </button>

        <p>{selectedDoc.description}</p>

        {/* Resizable and Draggable Card */}
        {selectedDoc.type === "embedded" ? (
      <div
                    className={`mb-4 col-md-12`}
                    // key={index}
                    onMouseDown={(e) => 
                       handleDragStart(e)
                 
                    }
                    style={{ position: "relative", minHeight:cardSizes?.height ? '500px' : selectedDoc?.size?.height}}
                  >
                    <Card
                      className="mb-3"
                      style={{
                        position: "absolute",
                        zIndex:cardZIndices || 1,
                        width: cardSizes?.width || "100%", // Default width
                        height: cardSizes?.height || selectedDoc?.size?.height, // Default height
                        left: cardSizes?.left || 0,
                        top: cardSizes?.top || 0,
                        border:"2px solid transparent"
                      }}
                    >
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                        <span className="flex-grow-1">{selectedDoc?.title}</span>

                          <i
                            className={
                              isBookmarked ? "fas fa-bookmark" : "far fa-bookmark"
                              // "far fa-bookmark"
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              marginTop: "3px",
                            }}
                            onClick={() => toggleBookmark(selectedDoc)}
                          ></i>
                          <i
                            className="bi bi-fullscreen"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleFullscreenClick(selectedDoc)}
                          ></i>
                        </div>
                        <iframe
                          src={selectedDoc.docs[0]}
                          width="100%"
                          height="90%"
                          style={{
                            borderRadius: "4px",
                          }}
                        ></iframe>

                        {/* Resize Handles */}
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, "top-left")
                       
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            cursor: "nwse-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                           handleResize(e, "top-right")
                         
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "nesw-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, "bottom-left")
                           
                          }
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            cursor: "nesw-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                             handleResize(e,"bottom-right")
                            
                          }
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            cursor: "nwse-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                      </CardBody>
                    </Card>
                  </div>
        ) : (
          // If type is not 'embedded', show a list of documents with their names and icons
          <div>
            <h3>Available Documents</h3>
            <div className="d-flex flex-wrap">
              {selectedDoc.docs.map((docUrl, index) => (
                <div
                  key={index}
                  className="doc-item card-custom m-2 p-3 text-center"
                  style={{
                    width: "400px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    overflow: "hidden",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <FaFileAlt
                    size={50}
                    style={{ marginBottom: "10px", color: "#6c757d" }}
                  />
                  <h5 style={{ color: "black" }}>{extractDocName(docUrl)}</h5>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => openInNewTab(docUrl)}
                  >
                    Open Document
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Modal for Full-Screen View */}
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="custom-fullscreen-modal"
            style={{ padding: 0, marginTop: 0 }}
          >
            <Modal.Body
              style={{
                padding: 0,
                margin: 0,
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: `${iframeWidth}px`,
                  border: "none",
                  position: "relative",
                  transition: "width 0.3s ease",
                }}
              >
                <iframe
                  src={modalIframeSrc}
                  title="Fullscreen Document"
                  style={{
                    border: "none",
                    width: "100%",
                    height: "100%",
                    transition: "width 0.3s ease",
                  }}
                ></iframe>
              </div>
            </Modal.Body>

            {buttonsVisible && (
              <div
                style={{
                  position: "fixed",
                  top: 10,
                  right: 15,
                  display: "flex",
                  gap: "10px",
                  zIndex: 1000,
                }}
              >
                <button
                  className="btn btn-theme btn-sm"
                  style={{ fontSize: 20, width: 40, borderRadius: 10 }}
                  onClick={increaseIframeWidth}
                >
                  +
                </button>
                <button
                  className="btn btn-theme btn-sm"
                  style={{ fontSize: 20, width: 40, borderRadius: 10 }}
                  onClick={decreaseIframeWidth}
                >
                  -
                </button>
                <button
                  className="btn btn-theme btn-sm"
                  style={{ fontSize: 20, width: 40, borderRadius: 10 }}
                  onClick={handleCloseModal}
                >
                  x
                </button>
              </div>
            )}
          </Modal>
      </div>
    </div>
  );
}

export default EmbeddedDoc;
